import { createStore } from "vuex";

const store = createStore({
  state: {
    // define global variables
    admin: {},
    image: "cccAssets/assets/images/news/news-1.jpg",
    perkyrabbit_image: "cccAssets/assets/images/perkyrabbit.png",
    resume_image: "cccAssets/assets/images/avatar2.png",
    // resume_image: "/public/uploads/attachment/resume_images/fTpCuz7mgE_6437f7feeacda.jpeg",

    alt: "Image",
    cccLogo: "cccAssets/assets/images/ewu-logo-header-white.png",
    cccMap: "cccAssets/assets/images/ewuMap.PNG",
    avatar: "cccAssets/assets/images/avatar.jpg",

    // backendUrl: "http://localhost:8000/",
    // backendUrl: "https://ccc-server.ewubd.edu/",

    // backendUrl: "http://server.fscd.xyz/",
    // backendUrl: "https://server-live.fscd.xyz/",
    // backendUrl: "https://server.fscd.xyz/api/",
    // backendUrl: "https://server.abulhossain.xyz/api/",
    // backendUrl: "http://server.fscd.xyz/",
    // backendUrl: window.location.origin.includes('fscd.xyz') ? "http://server.fscd.xyz" : "http://localhost:8000" ,

  },

  getters: {
    // get state variables value
    getAdmin: function (state) {
      return state.admin;
    },
  },

  actions: {
    //action to be performed
    setAdmin(context, payload) {
      context.commit("updateAdmin", payload);
    },

    removeAdmin(context) {
      context.commit("updateAdmin", null);
    },
  },

  mutations: {
    // update state variables value
    updateAdmin(state, admin) {
      state.admin = admin;
    },
  },
});

export default store;