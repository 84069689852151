import { createSSRApp } from "vue";
import App from "./App.vue";
import axiosClient from '../src/axios_client'
import { createRouter } from "./router";
import store from "./store";
import { createHead } from "@vueuse/head";
import '@/registerServiceWorker';
import VueSocialSharing from 'vue-social-sharing';

// vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// Elementor-Plus setup start
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// progressbar start
import VueProgressBar from "@aacassandra/vue3-progressbar";
const options = {
  color: "#50d38a",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

// Vuetify setup start
// import 'vuetify/styles'
// import { createVuetify } from 'vuetify'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'
// const vuetify = createVuetify({
//   components,
//   directives,
// })

// Service Worker for PWA start
// if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const serviceWorkerPath = '/service-worker.js';

//     navigator.serviceWorker
//       .register(serviceWorkerPath)
//       .then((registration) => {
//         console.log('Service worker registered:', registration);
//       })
//       .catch((error) => {
//         console.error('Service worker registration failed:', error);
//       });
//   });
// }

// add global function start
const backendUrl = import.meta.env.VITE_API_BASE_URL;
const $setting = async (key) => {
  try {
    const response = await axiosClient.get(`${backendUrl}setting/fetch`, {
      params: { keysArray: key },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching setting:', error);
    return false;
  }
};

export function createApp() {
  const app = createSSRApp(App);
  const router = createRouter();
  app.use(router);
  app.use(store);
  const head = createHead();
  app.use(head);
  app.use(VueSocialSharing);
  app.use(VueSweetalert2);
  app.use(ElementPlus);
  app.use(VueProgressBar, options);
  // app.use(vuetify);

  // add global function to app config
  app.config.globalProperties.$setting = $setting;
    
  return { app, router, head };
}









// import { createSSRApp } from "vue";
// import App from "./App.vue";
// import axiosClient from 'axiosClient';
// import { createRouter } from "./router";
// import store from "./store";

// // progressbar start
// import VueProgressBar from "@aacassandra/vue3-progressbar";
// const options = {
//   color: "#50d38a",
//   failedColor: "#874b4b",
//   thickness: "5px",
//   transition: {
//     speed: "0.2s",
//     opacity: "0.6s",
//     termination: 300,
//   },
//   autoRevert: true,
//   location: "top",
//   inverse: false,
// };
// // progressbar end

// // vue sweetalert2
// import VueSweetalert2 from "vue-sweetalert2";
// import "sweetalert2/dist/sweetalert2.min.css";

// // Elementor-Plus 
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'

// // social sharing
// import VueSocialSharing from 'vue-social-sharing'

// // Vuetify
// // import 'vuetify/styles'
// // import { createVuetify } from 'vuetify'
// // import * as components from 'vuetify/components'
// // import * as directives from 'vuetify/directives'

// // const vuetify = createVuetify({
// //   components,
// //   directives,
// // })

// // Service Worker for PWA start
// if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const serviceWorkerPath = '/service-worker.js';

//     navigator.serviceWorker
//       .register(serviceWorkerPath)
//       .then((registration) => {
//         console.log('Service worker registered:', registration);
//       })
//       .catch((error) => {
//         console.error('Service worker registration failed:', error);
//       });
//   });
// }
// // Service Worker for PWA end

// export function createApp() {
//     const app = createSSRApp(App);
//     const router = createRouter();
//     app.use(router);
//     app.use(store);
//     app.use(VueProgressBar, options);
//     app.use(VueSweetalert2);
//     app.use(ElementPlus);
//     app.use(VueSocialSharing);
//     // app.use(vuetify);

//     // add global function start
//     let backendUrl = import.meta.env.VITE_API_BASE_URL;
//     app.config.globalProperties.$setting = function(key) {
//         return axiosClient.get(`${backendUrl}setting/fetch`, {params: { keysArray: key }})
//         .then((response) => {return response.data})
//         .catch(error => false);
//     }
//     // add global function end
    
//     return { app, router };
// }