import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
} from "vue-router";

import LayoutVue from "../components/SanuHome/Layout.vue";
import AuthLayoutVue from "../components/SanuHome/AuthLayout.vue";
import Cookies from 'js-cookie';
import axiosClient from '../axios_client'

// scout 
import JobPortalHome from "../views/JobPortal/JobPortalHome.vue";


// jobportal landing home routes 
const routes = [
  {
    path: "/",
    component: LayoutVue,
  
      // meta: {
      //     requiresGuest: true
      // },
    children: [
      {
        path: "/",
        name: "scout_home",
        component: JobPortalHome,
        meta:{
            requiresAuth: true
          },
      },
      {
        path: "category-jobs",
        name: "category_jobs",
        // component: CategoryJobs,
        component: () => import("../views/JobPortal/CategoryJobs.vue"),
      },
      {
        path: "allcategory-jobs",
        name: "allcategory_jobs",
        // component: AllCategoryJobs,
        component: () => import("../views/JobPortal/AllCategoryJobs.vue"),
      },
      {
        path: "search-jobs",
        name: "search_jobs",
        // component: SearchJobs,
        component: () => import("../views/JobPortal/SearchJobs.vue"),
      },
      {
        path: "job-blogs",
        name: "job_blog",
        // component: ScoutJobBlog,
        component: () => import("../views/JobPortal/ScoutJobBlog.vue"),
      },
      {
        path: "single-job-blog",
        name: "single_job_blog",
        // component: SingleJobBlog,
        component: () => import("../views/JobPortal/SingleJobBlog.vue"),
      },
      {
        path: "contact",
        name: "contact",
        // component: Contact,
        component: () => import("../views/JobPortal/Contact.vue"),
      },

      {
        path: "login",
        name: "scout_login",
        // component: ScoutLogin,
        component: () => import("../views/JobPortal/ScoutLogin.vue"),
      },
      // {
      //   path: "createprofile",
      //   name: "createprofile",
      //   component: () => import("../views/JobPortal/CreateProfile.vue"),
      // },
      // {
      //   path: "employee-create-profile",
      //   name: "employe_createprofile",
      //   component: () => import("../views/JobPortal/EmployeeCreateProfile.vue"),
      // },
      {
        path: "loginprofile",
        name: "loginprofile",
        // component: LoginProfile,
        component: () => import("../views/JobPortal/LoginProfile.vue"),
      },
      {
        path: "jobprofilecreate",
        name: "jobprofilecreate",
        // component: jobprofilecreate,
        component: () => import("../views/JobPortal/JobProfileCreate.vue"),
      },


      {
        path: "alljobs",
        name: "scout_alljob",
        // component: ScoutAllJob,
        meta:{
        requiresAuth: true
     },
        component: () => import("../views/JobPortal/ScoutAllJob.vue"),
      },
      {
        path: "internships",
        name: "internships",
        // component: ScoutAllJob,
        meta:{
        requiresAuth: true
     },
        component: () => import("../views/JobPortal/Internship.vue"),
      },
      {
        path: "job-details",
        name: "job_details",
        // component: JobDetails,
              meta:{
         requiresAuth: true
     },
        component: () => import("../views/JobPortal/JobDetails.vue"),
      },
      {
        path: "browse-resume",
        name: "browse_resume",
        // component: BrowseResume,
        component: () => import("../views/JobPortal/BrowseResume.vue"),
      },
      {
        path: "applyjob",
        name: "applyjob",
        // component: applyJob,
        component: () => import("../views/JobPortal/ApplyJob.vue"),
      },
      
      
    ],
  },

  // auth route
  {
    path: "/",
    component: AuthLayoutVue,
    // meta: {
    //     requiresGuest: true
    // },

    children: [
      {
        path: "login",
        name: "scout_login",
        // component: ScoutLogin,
        component: () => import("../views/JobPortal/ScoutLogin.vue"),
      },

      {
        path: "createprofile",
        name: "createprofile",
        component: () => import("../views/JobPortal/CreateProfile.vue"),
      },

      {
        path: "employee-create-profile",
        name: "employe_createprofile",
        component: () => import("../views/JobPortal/EmployeeCreateProfile.vue"),
      },
      
      {
        path: "loginprofile",
        name: "loginprofile",
        // component: LoginProfile,
        component: () => import("../views/JobPortal/LoginProfile.vue"),
      },

      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("../views/Auth/ForgotPassword.vue"),
      },

      {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("../views/Auth/ResetPassword.vue"),
      },

      {
        path: "jobprofilecreate",
        name: "jobprofilecreate",
        // component: jobprofilecreate,
        component: () => import("../views/JobPortal/JobProfileCreate.vue"),
      },
    ],
  },



  // Dashboard route
  {
  path: "/app",
  name: "app",
  component: () => import("../components/Admin/Layout.vue"),
    meta:{
         requiresAuth: true
    },

    children: [
      {
        path: "/dashboard",
        name: "app.dashboard",
        component: () => import("../views/Admin/Dashboard.vue"),
      },

      {
        path: "/resume-dashboard",
        name: "app.resume_dashboard",
        component: () => import("../views/Admin/ResumeDashboard.vue"),
      },

      {
        path: "/notice-event",
        name: "app.notice-event",
        component: () => import("../views/Admin/NoticeEvent/Index.vue"),
      },

      {
        path: "/dashboard-managejobs",
        name: "dashboard_managejobs",
        component: () => import("../views/Admin/Employeers/ManageJobs/Index.vue"),
      },

      {
        path: "/dashboard-manage-all-jobs",
        name: "dashboard_manage_all_jobs",
        component: () => import("../views/Admin/Employeers/ManageAllJob/Index.vue"),
      },

      {
        path: "/dashboard-createjob",
        name: "dashboard_createjob",
        component: () => import("../views/Admin/Employeers/ManageJobs/Create.vue"),
      },

      {
        path: "/dashboard-editjob",
        name: "dashboard_editjob",
        component: () => import("../views/Admin/Employeers/ManageJobs/Edit.vue"),
      },
      {
        path: "/dashboard-viewjob",
        name: "dashboard_viewjob",
        component: () => import("../views/Admin/Employeers/ManageJobs/View.vue"),
      },
      {
        path: "/dashboard-jobapplications",
        name: "dashboard_jobapplications",
        component: () => import("../views/Admin/Employeers/ManageJobApplications/Index.vue"),
      },

      {
        path: "/dashboard-jobapplicants",
        name: "dashboard_jobapplicants",
        component: () => import("../views/Admin/Employeers/ManageJobApplicants/Index.vue"),
      },

      {
        path: "/dashboard-jobregister",
        name: "dashboard_jobregister",
        component: () => import("../views/Admin/Candidates/JobRegister.vue"),
      },

      {
        path: "/dashboard-resumeview",
        name: "dashboard_resumeview",
        component: () => import("../views/Admin/Candidates/ResumeView.vue"),
      },

      {
        path: "/dashboard-videoresume",
        name: "dashboard_video_resumeview",
        component: () => import("../views/Admin/Candidates/VideoResumeView.vue"),
      },

      {
        path: "/dashboard-applyedjob-list",
        name: "dashboard_applyed_job_list",
        component: () => import("../views/Admin/Candidates/ApplyedJobView.vue"),
      },
      {
        path: "/dashboard-applyed-workshops",
        name: "dashboard_applyed_workshops",
        component: () => import("../views/Admin/Candidates/ApplyedWorkshop/Index.vue"),
      },
      {
        path: "/dashboard-applyed-workshop-view",
        name: "dashboard_applyed_workshop_view",
        component: () => import("../views/Admin/Candidates/ApplyedWorkshop/View.vue"),
      },
      // {
      //   path: "/dashboard-viewjob",
      //   name: "dashboard_viewjob",
      //   component: () => import("../views/Admin/Employeers/ManageJobs/View.vue"),
      // },
      {
        path: "/dashboard-applyed-trainings",
        name: "dashboard_applyed_trainings",
        component: () => import("../views/Admin/Candidates/ApplyedTraining/Index.vue"),
      },

      {
        path: "/dashboard-applyed-training-view",
        name: "dashboard_applyed_training_view",
        component: () => import("../views/Admin/Candidates/ApplyedTraining/View.vue"),
      },

      {
        path: "/dashboard-applyed-internships",
        name: "dashboard_applyed_internships",
        component: () => import("../views/Admin/Candidates/ApplyedInternship/Index.vue"),
      },

      {
        path: "/dashboard-applyed-internships-view",
        name: "dashboard_applyed_internships_view",
        component: () => import("../views/Admin/Candidates/ApplyedInternship/View.vue"),
      },

      {
        path: "/dashboard-resumebuild",
        name: "dashboard_resumebuild",
        component: () => import("../views/Admin/Candidates/ResumeBuild.vue"),

        children: [
          {
            path: "/dashboard-resumecreate-step_01_view",
            name: "dashboard_step_one",
            component: () => import("../views/Admin/Candidates/Step_One.vue"),
          },
          {
            path: "/dashboard-resumecreate-step_02_view",
            name: "dashboard_two",
            component: () => import("../views/Admin/Candidates/Step_Two.vue"),
          },
          {
            path: "/dashboard-resumecreate-step_03_view",
            name: "dashboard_three",
            component: () => import("../views/Admin/Candidates/Step_Three.vue"),
          },

          {
            path: "/dashboard-resumecreate-step_04_view",
            name: "dashboard_fore",
            component: () => import("../views/Admin/Candidates/Step_Fore.vue"),
          },

          {
            path: "/dashboard-resumecreate-step_05_view",
            name: "dashboard_five",
            component: () => import("../views/Admin/Candidates/Step_Five.vue"),
          },
        ],
      
      },
      {
        path: "/dashboard-profile",
        name: "dashboard_profile",
        component: () => import("../views/Admin/Candidates/Profile.vue"),
      },
      {
        path: "/dashboard-2fa",
        name: "dashboard_2fa",
        component: () => import("../views/Admin/Candidates/2fa.vue"),
      },

    ],
  },

  {
    path: "/resume-view",
    name: "public_resume",
    component: () => import("../views/Admin/Candidates/PublicResumeView.vue"),
  },

];

export const createRouter = () => {
  const router = _createRouter({
      history: import.meta.env.SSR
      ? createMemoryHistory("/")
      : createWebHistory("/"),
      routes,
  });

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresGuest = to.matched.some(record => record.meta.requiresGuest)

    if (Cookies.get('authIdCookie') && Cookies.get('alumniLoggedInTokenCookie')) {
      axiosClient.get(`${import.meta.env.VITE_API_BASE_URL}test420`,{
        params:{
            token:Cookies.get('alumniLoggedInTokenCookie').split('|')[1]
        }
      }).then((response) => {
        if (response.data.data.resume == null && requiresAuth){
          next({ name: 'createprofile' })
          // next({name: 'app.dashboard'})
        } else if(response.data.data.resume != null && requiresGuest){
          next({name: 'app.dashboard'})
        } else {
          next()
        }
      })
    } else if (Cookies.get('authIdCookie') && Cookies.get('jobUserLoggedInToken')) {
      axiosClient.get(`${import.meta.env.VITE_API_BASE_URL}test420`).then((response) => {
        if (response.data.data.resume == null && requiresAuth && response.data.data.employment_status == 'Alumni'){
          next()
        } else if (response.data.data.resume == null && requiresAuth && response.data.data.employment_status == 'Student'){
           next({ name: 'createprofile' })
        } else if(response.data.data.resume != null && requiresGuest){
          next({name: 'app.dashboard'})
        } else {
          next()
        }
      })
    } else{
      if (requiresAuth){
        next({ name: 'loginprofile' })
      } else{
        next()
      }
    }
  })

  return router;
};
