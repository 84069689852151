import axios from 'axios';

// const url = 'http://127.0.0.1:8000'; // Replace with your server URL
const url = 'https://ccc-server.ewubd.edu'; // Replace with your server URL

const axiosClient = axios.create({
  baseURL : url
});

axiosClient.interceptors.request.use(config => {
  const token = localStorage.getItem('jobUserLoggedInToken');

  // Check if token exists and add it to the Authorization header
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosClient;



