<template>
    <div id="home">
        <header>
            <!-- Start Navbar Area -->
            <div class="navbar-area nav-bg-1">
                <div class="mobile-responsive-nav">
                    <div class="container">
                        <div class="mobile-responsive-menu">
                            <div class="logo">
                                <a href="#">
                                    <img :src="settingKeyAndValueArray.jobPortalHeaderLogo" alt="logo" style="height: 45px;">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="desktop-nav mx-4">
                    <div class="container-fluid">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <router-link :to="{ name: 'scout_home' }" class="navbar-brand">
                                <img :src="settingKeyAndValueArray.jobPortalHeaderLogo" :alt="logo" style="height: 45px;">
                            </router-link>

                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav ms-auto">
                                    <!-- <li class="nav-item">
                                        <router-link :to="{ name: 'scout_home' }" class="nav-link active">Home</router-link>
                                    </li> -->

                                    <li class="nav-item">
                                        <a href="#" class="nav-link"> Function & Service </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a :href="cccUrl+ `/training-workshop-seminars`" class="nav-link"> Training/Workshop/Seminar </a>
                                            </li>
                                            <li class="nav-item">
                                                <a :href="cccUrl+`/socio-psyche-counseling`"> Socio-Phyche-Counseling</a>
                                            </li>
                                            <li class="nav-item">
                                                <a :href="cccUrl+`/internships`">Internships</a>
                                            </li>
                                        </ul>
                                    </li>
                                                                            
                                    <li class="nav-item">
                                        <a :href="cccUrl" >CCC Home</a>
                                    </li>

                                    <li class="nav-item">
                                        <a :href="alumniUrl" >Alumni</a>
                                    </li>

                                    <li class="nav-item"></li>

                                    <li class="nav-item" v-if="!authId">
                                        <router-link :to="{ name: 'loginprofile' }">Sign In </router-link>
                                    </li>

                                    <li class="nav-item" v-if="!authId">
                                        <div class="btn-group">
                                            <a href="" class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Create Account</a>
                                            <div class="dropdown-menu dropdown-menu-md mt-5" style="width:110%">
                                                <div class="card mt-2">
                                                <div class="card-body">
                                                    <h3 class="card-title">Alumni/Student</h3>
                                                    <p>Sign in or create your account to manage your profile</p>
                                                    <router-link type="button" class="btn btn-outline-info waves-effect waves-light"   :to="{ name: 'createprofile' }">Create Account</router-link>
                                                </div>
                                                </div>
                                                <div class="card mt-2">
                                                <div class="card-body">
                                                    <h3 class="card-title">Employee/Company</h3>
                                                    <p>Sign in or create account to find the best candidates in the fastest way</p>
                                                    <router-link type="button" class="btn btn-outline-info waves-effect waves-light"   :to="{ name: 'employe_createprofile' }">Create Account</router-link>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="nav-item" v-if="authId">
                                        <router-link :to="{ name: 'app.dashboard' }" class="nav-link">
                                            <i class="fa-solid fa-user"></i> Profile
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="dot-menu">
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <router-view> </router-view>

        <footer>
            <!--Start Footer Area-->
            <div class="footer-area pt-30 pb-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-logo-area">
                                <a href="#">
                                    <img :src="settingKeyAndValueArray.jobPortalFooterLogo" alt="footer_logo" style="height: 45px;">
                                </a>
                                <p>{{ settingKeyAndValueArray.jobPortalSiteAddress }}</p>
                                <div class="contact-list">
                                    <ul>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterContactOne }}, {{ settingKeyAndValueArray.jobPortalFooterContactTwo }}, {{ settingKeyAndValueArray.jobPortalFooterContactThree }} </a></li>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterSiteEmail }} </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-3 col-sm-6">
                            <div class="footer-widjet">
                            
                                 <div class="list">
                                    <ul>
                                        <li><a href="campus-life.html">Accessibility</a></li>
                                        <li><a href="campus-life.html">Financial Aid</a></li>
                                        <li><a href="campus-life.html">Food Services</a></li>
                                        <li><a href="campus-life.html">Housing</a></li>
                                    </ul>
                                    <ul>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterContactOne }}, {{ settingKeyAndValueArray.jobPortalFooterContactTwo }}, {{ settingKeyAndValueArray.jobPortalFooterContactThree }} </a></li>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterSiteEmail }} </a></li>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterContactOne }}, {{ settingKeyAndValueArray.jobPortalFooterContactTwo }}, {{ settingKeyAndValueArray.jobPortalFooterContactThree }} </a></li>
                                        <li><a href="#"> {{ settingKeyAndValueArray.jobPortalFooterSiteEmail }} </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widjet">
                             
                                <div class="list">
                                    <ul>
                                        <li><a href="campus-life.html">Acedemic</a></li>
                                        <li>
                                            <a href="campus-life.html">Planning & Administration</a>
                                        </li>
                                        <li><a href="campus-life.html">Campus Safety</a></li>
                                        <li>
                                            <a href="campus-life.html">Office of the Chancellor</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widjet">
                                <h3> {{ settingKeyAndValueArray.jobPortalFooterResourcesText }} </h3>
                                <div class="list">
                                    <ul>
                                        <li><a :href="cccUrl+'/resource-all'">{{settingKeyAndValueArray.jobPortalFooterResourcesLinkOneTitle}}</a></li>
                                        <li><a :href="cccUrl+'/ccc-notice-event'">{{settingKeyAndValueArray.jobPortalFooterResourcesLinkTwoTitle}}</a></li>
                                        <li><a :href="cccUrl+'/internships'">{{settingKeyAndValueArray.jobPortalFooterResourcesLinkThreeTitle}}</a></li>
                                        <li><a :href="settingKeyAndValueArray.jobPortalFooterResourcesLinkFore">{{settingKeyAndValueArray.jobPortalFooterResourcesLinkForeTitle}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widjet">
                                <h3> {{ settingKeyAndValueArray.jobPortalFooterInformationText }} </h3>
                                <div class="list">
                                    <ul>
                                        <li><a :href="cccUrl">{{settingKeyAndValueArray.jobPortalFooterInformationLinkOneTitle}}</a></li>
                                        <li><a :href="cccUrl+'/ccc-about'">{{settingKeyAndValueArray.jobPortalFooterInformationLinkTwoTitle}}</a></li>
                                        <li><a :href="cccUrl+'/ccc-faq'">{{settingKeyAndValueArray.jobPortalFooterInformationLinkThreeTitle}}</a></li>
                                        <li><a :href="settingKeyAndValueArray.jobPortalFooterInformationLinkFore">{{settingKeyAndValueArray.jobPortalFooterInformationLinkForeTitle}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widjet">
                                <h3> {{ settingKeyAndValueArray.jobPortalFooterLocationMapText }} </h3>
                                <div class="list">
                                    <div class="border-outline">
                                        <a href="https://goo.gl/maps/QnYrTZHGPYMzSQfK7">
                                            <img style="width: 99%;" :src="$store.state.cccMap" :alt="$store.state.alt">
                                        </a>
                                    </div>

                                    <p class="text-white" style="margin-top: 10px">
                                        Follow US:
                                        <ShareNetwork
                                            network="facebook"
                                            url="https://www.facebook.com/myewu"
                                            title="Job  portal login"
                                            description="Job  portal login"
                                            quote="Thank you"
                                            hashtags="jobportal"
                                        >
                                            <i class="ri-facebook-fill circular-icon"></i>
                                        </ShareNetwork>

                                        <ShareNetwork
                                            network="linkedin"
                                            url="https://www.linkedin.com/school/ewubd/"
                                            title="Job  portal login"
                                            description="Job  portal login"
                                            quote="Thank you"
                                            hashtags="jobportal"
                                        >
                                            <i class="ri-linkedin-fill circular-icon"></i>
                                        </ShareNetwork>

                                        <ShareNetwork
                                            network="twitter"
                                            url="https://twitter.com/myewubd"
                                            title="Job  portal login"
                                            description="Job  portal login"
                                            quote="Thank you"
                                            hashtags="jobportal"
                                        >
                                            <i class="ri-twitter-fill circular-icon"></i>
                                        </ShareNetwork>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--End Footer Area-->
            <div style="margin-top: 2px"></div>

            <!--Start Copyright Area-->
            <div class="copyright-area">
                <div class="container">
                    <div class="copyright">
                        <div class="row">
                            <div class="col-lg-10 col-md-4">
                                <div class="social-content">
                                    <ul>
                                        <li><span>Quick Links</span></li>
                                        <li>
                                            <a :href="cccUrl" type="button" class="btn btn-secondary btn-sm btn-responsive btn-block" style="border-radius: 20%">
                                                {{ settingKeyAndValueArray.jobPortalFooterQuickLinksOneTitle }}
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="alumniUrl+'/register'" type="button" class="btn btn-secondary btn-sm btn-responsive btn-block" style="border-radius: 20%">
                                                {{ settingKeyAndValueArray.jobPortalFooterQuickLinksTwoTitle }}
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="cccUrl+'/clubs-all'" type="button" class="btn btn-secondary btn-sm btn-responsive btn-block" style="border-radius: 20%">
                                                {{ settingKeyAndValueArray.jobPortalFooterQuickLinksThreeTitle }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--End Copyright Area-->

            <!-- Start Go Top Area -->
            <div class="go-top">
                <i class="ri-arrow-up-s-line"></i>
                <i class="ri-arrow-up-s-line"></i>
            </div>
            <!-- End Go Top Area -->
        </footer>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: "Layout",

    data() {
        return {
            backendUrl: import.meta.env.VITE_API_BASE_URL,
            cccUrl: import.meta.env.VITE_API_CCC_URL,
            jobsUrl: import.meta.env.VITE_API_JOBS_URL,
            alumniUrl: import.meta.env.VITE_API_ALUMNI_URL,


            authId: Cookies.get('authIdCookie'),

            settingKeyAndValueArray: [
                'jobPortalHeaderLogo',
                'jobPortalFooterLogo',
                'jobPortalSiteAddress',

                'jobPortalFooterResourcesText',
                'jobPortalFooterResourcesLinkOneTitle',
                'jobPortalFooterResourcesLinkTwoTitle',
                'jobPortalFooterResourcesLinkThreeTitle',
                'jobPortalFooterResourcesLinkForeTitle',
                'jobPortalFooterResourcesLinkOne',
                'jobPortalFooterResourcesLinkTwo',
                'jobPortalFooterResourcesLinkThree',
                'jobPortalFooterResourcesLinkFore',

                'jobPortalFooterInformationText',
                'jobPortalFooterInformationLinkOneTitle',
                'jobPortalFooterInformationLinkTwoTitle',
                'jobPortalFooterInformationLinkThreeTitle',
                'jobPortalFooterInformationLinkForeTitle',
                'jobPortalFooterInformationLinkOne',
                'jobPortalFooterInformationLinkTwo',
                'jobPortalFooterInformationLinkThree',
                'jobPortalFooterInformationLinkFore',

                'jobPortalFooterContactOne',
                'jobPortalFooterContactTwo',
                'jobPortalFooterContactThree',
                'jobPortalFooterSiteEmail',
                'jobPortalFooterStudentText',
                'jobPortalFooterOurCampusText',
                'jobPortalFooterLocationMapText',
                'jobPortalFooterQuickLinksOneTitle',
                'jobPortalFooterQuickLinksOne',
                'jobPortalFooterQuickLinksTwoTitle',
                'jobPortalFooterQuickLinksTwo',
                'jobPortalFooterQuickLinksThreeTitle',
                'jobPortalFooterQuickLinksThree',
            ]
        };
    },

    mounted() {
        this.$setting(this.settingKeyAndValueArray).then(value => {
            this.settingKeyAndValueArray = value;
        })
    },

    beforeCreate() {
        // CSS
        let bodyStyle1 = document.createElement('link')
        bodyStyle1.setAttribute('href', 'home/assets/css/bootstrap.min.css')
        bodyStyle1.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle1)

        let bodyStyle2 = document.createElement('link')
        bodyStyle2.setAttribute('href', 'home/assets/css/meanmenu.css')
        bodyStyle2.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle2)

        let bodyStyle3 = document.createElement('link')
        bodyStyle3.setAttribute('href', 'home/assets/css/owl.carousel.min.css')
        bodyStyle3.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle3)

        let bodyStyle4 = document.createElement('link')
        bodyStyle4.setAttribute('href', 'home/assets/css/owl.theme.default.min.css')
        bodyStyle4.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle4)

        let bodyStyle5 = document.createElement('link')
        bodyStyle5.setAttribute('href', 'home/assets/css/magnific-popup.css')
        bodyStyle5.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle5)

        let bodyStyle6 = document.createElement('link')
        bodyStyle6.setAttribute('href', 'home/assets/css/flaticon.css')
        bodyStyle6.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle6)

        let bodyStyle7 = document.createElement('link')
        bodyStyle7.setAttribute('href', 'home/assets/css/remixicon.css')
        bodyStyle7.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle7)

        let bodyStyle8 = document.createElement('link')
        bodyStyle8.setAttribute('href', 'home/assets/css/odometer.min.css')
        bodyStyle8.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle8)

        let bodyStyle9 = document.createElement('link')
        bodyStyle9.setAttribute('href', 'home/assets/css/aos.css')
        bodyStyle9.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle9)

        let bodyStyle10 = document.createElement('link')
        bodyStyle10.setAttribute('href', 'home/assets/css/style.css')
        bodyStyle10.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle10)

        let bodyStyle11 = document.createElement('link')
        bodyStyle11.setAttribute('href', 'home/assets/css/dark.css')
        bodyStyle11.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle11)

        let bodyStyle12 = document.createElement('link')
        bodyStyle12.setAttribute('href', 'home/assets/css/responsive.css')
        bodyStyle12.setAttribute('rel', 'stylesheet')
        document.head.appendChild(bodyStyle12)

        let bodyScript13 = document.createElement('script')
        bodyScript13.setAttribute('src', 'home/assets/js/custom.js')
        document.body.appendChild(bodyScript13)
    },
}
</script>

<style>
    .circular-icon {
        background-color: white;
        border-radius: 50%;
        color: red;
        padding: 10px;
        margin:5px;
        transition: background-color 0.6s ease;
    }

    .circular-icon:hover {
        background-color: red;
        color: white;
    }
</style>